@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@700&display=swap');

@font-face {
    font-family: 'Horizon';
    src: url('./fonts/Horizon.woff') format('woff');
    font-weight: bold;
}

html {
    scroll-behavior: smooth;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a {
    color: var(--cyber-green);
    text-decoration: none;
}

/* Customize your main colors in :root variables */
:root {
    --main-background-color: #343a50;
    --card-background-color: #51576d;
    --card-background-lighter-color: #7a7f92;
    --main-text-color: #f7f6f4;
    --title-text-color: #3cba8b;
    --black: #000000;
    --cyber-green: #AAFF49;
    --font-horizon: 'Horizon';
}

body {
    background-color: var(--cyber-green);
    color: white;
    font-size: 1rem;
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 700;
    line-height: normal !important;
    -webkit-animation: fadein 2s;
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
        background: black;
    }
    to {
        opacity: 1;
        background: black;
    }
}

.header-scroll {
    background: black;
}

.header-scroll .menu-items {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

h2,
p {
    color: var(--main-text-color);
}

h3 {
    font-weight: normal;
}

.wallet-adapter-button-start-icon {
    display: none !important;
}

.wallet-adapter-button[disabled] {
    color: var(--cyber-green) !important;
    background-color: transparent !important;
    justify-content: center !important;
}

.MuiButton-contained.Mui-disabled {
    background-color: transparent !important;
    font-size: 2rem !important;
    border-radius: 0 !important;
    text-transform: uppercase !important;
    font-family: var(--font-horizon);
    font-weight: bold !important;
    color: var(--cyber-green);
}

.MuiButton-contained.Mui-disabled:hover {
    color: white !important;
}

.MuiCircularProgress-colorPrimary {
    color: var(--cyber-green) !important;;
    vertical-align: middle !important;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 0.5em;
}

::-webkit-scrollbar-track {
    background-color: black;
}

::-webkit-scrollbar-thumb {
    background: var(--cyber-green);
}

/* ............/navbar/............ *

/* desktop mode............/// */

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
    display: none;
}

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    max-width: inherit;
    box-sizing: border-box;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-items {
    order: 2;
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-right: 5rem;
    margin: 0;
}

.menu-items li {
    list-style: none;
    margin-left: 2rem;
}

.menu-items li:first-child {
    margin-left: 0;
}

.menu-items a {
    color: white;
    text-decoration: none;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.menu-items a:hover {
    color: var(--cyber-green);
}

.logo {
    order: 1;
    margin: 0;
    padding-left: 5rem;
}

/* ......../ media query /.......... */

@media (max-width: 768px) {
    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
        display: block;
    }

    .navbar-container {
        display: block;
        position: relative;
        height: 4rem;
    }

    .navbar-container input[type="checkbox"] {
        position: absolute;
        display: block;
        width: 30px;
        top: 20px;
        left: 20px;
        z-index: 5;
        opacity: 0;
    }

    .navbar-container .hamburger-lines {
        height: 18px;
        width: 35px;
        position: absolute;
        top: 23px;
        left: 20px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        background: var(--cyber-green);

    }

    .navbar-container .hamburger-lines .line1 {
        transform-origin: 9px 0%;
        transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
        transform-origin: 0 100%;
        transition: transform 0.4s ease-in-out;
    }

    .navbar .menu-items {
        padding: 0;
        padding-top: 100px !important;
        background: black;
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin: 0;
        transition: transform 0.5s ease-in-out;
        transform: translate(-150%);
    }

    .navbar .menu-items li {
        margin-bottom: 2rem;
        font-size: 1.3rem;
        margin-left: 0;
    }

    .logo {
        position: absolute;
        top: 12px;
        right: 1rem;
        max-height: 40px;
    }

    .navbar-container input[type="checkbox"]:checked ~ .menu-items {
        transform: translateX(0);
    }

    /*.navbar-container input[type="checkbox"]:checked ~ .menu-items a {*/
    /*    text-transform: unset;*/
    /*}*/

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
        transform: rotate(35deg);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
        transform: rotate(-35deg);
    }
}

@media (max-width: 500px) {
    .navbar .menu-items li {
        font-size: 1.6rem;
    }

    .navbar .menu-items li {
        margin: 0;
        margin-bottom: 2rem;
        font-size: 1.8rem;
        font-weight: 500;
    }
}

.scroll-off {
    overflow: hidden !important;
}